<template>
  <div class="modal fade" id="modal-google-campaign-details" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-campaign-detailsLabel">Campaign Attributes</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="loading" class="d-flex items-center justify-content-center w-100 py-5">
            <div class="spinner-border spinner-border-sm"></div>
          </div>
          <div class="statbox widget box p-0" v-else-if="!loading && campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <tbody>
                  <tr>
                    <th>Google Ads Account ID</th>
                    <td>{{ campaign.customerId }}</td>
                  </tr>
                  <tr>
                    <th>Campaign Name</th>
                    <td>{{ campaign.name }}</td>
                  </tr>
                  <tr>
                    <th>Campaign ID</th>
                    <td>{{ campaign.id }}</td>
                  </tr>
                  <tr>
                    <th width="30%">Budget</th>
                    <td>
                      <div class="row" v-if="!editBudget">
                        <div class="col-md-10">
                          ${{ campaign.budget.toFixed(2) }} daily average (${{ campaign.monthly_max_budget.toFixed(2) }} monthly max)
                        </div>
                        <div class="col-md-2 text-end">
                          <a href="#" @click.prevent="editBudget = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-10">
                          <div class="form-group mb-0">
                            <input :class="['form-control form-control-sm', {'is-invalid': errorFor('amount')}]"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter New Daily Budget"
                                   v-model="newBudgetForm.amount">
                            <v-errors :errors="errorFor('amount')" />
                          </div>
                        </div>
                        <div class="col-md-2 text-end">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-secondary me-1"
                                  @click="editBudget = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveBudget">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="30%">Maximum Cost-Per-Click Bid</th>
                    <td>
                      <div class="row" v-if="!editMaxCpc">
                        <div class="col-md-10">
                          <span v-if="campaign.max_cpc">${{ campaign.max_cpc.toFixed(2) }}</span>
                          <span v-else>No maximum</span>
                        </div>
                        <div class="col-md-2 text-end">
                          <a href="#" @click.prevent="editMaxCpc = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-10">
                          <div class="form-group mb-0">
                            <input :class="['form-control form-control-sm', {'is-invalid': errorFor('amount')}]"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter Max CPC"
                                   v-model="newMaxCpcForm.amount">
                            <v-errors :errors="errorFor('amount')" />
                          </div>
                        </div>
                        <div class="col-md-2 text-end">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-secondary me-1"
                                  @click="editMaxCpc = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveMaxCpc">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Campaign Start Date</th>
                    <td>{{ campaign.start_date }}</td>
                  </tr>
                  <tr>
                    <th>Campaign Status</th>
                    <td>{{ campaign.status }}</td>
                  </tr>
                  <tr>
                    <th>Campaign Serving Status</th>
                    <td>{{ campaign.serving_status }}</td>
                  </tr>
                  <tr>
                    <th>Ad Serving Optimization Status</th>
                    <td>{{ campaign.ad_serving_optimization_status }}</td>
                  </tr>
                  <tr>
                    <th>Bidding Strategy</th>
                    <td>{{ campaign.bidding_strategy }}</td>
                  </tr>
                  <tr v-if="campaign.reasons.length">
                    <th>Google Ad Status</th>
                    <td>
                      <b class="text-danger">Disapproved</b>
                      <ul>
                        <li v-for="(rsn, i) in campaign.reasons"
                            :key="'reason_'+i">{{ rsn }}</li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
          <div v-else class="text-center py-5">
            <h2>No data found :)</h2>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      newBudgetForm: {
        amount: '',
        campaignId: ''
      },
      newMaxCpcForm: {
        amount: '',
        campaignId: ''
      },
      modal: null,
      editBudget: false,
      editMaxCpc: false,
      campaign: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-google-campaign-details'))
  },
  methods: {
    saveBudget() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/google/change/budget', this.newBudgetForm)
        .then(() => {

          this.campaign.budget = parseFloat(this.newBudgetForm.amount);
          this.newBudgetForm.amount = '';
          this.editBudget = false;
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveMaxCpc() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/google/change/max-cpc', this.newMaxCpcForm)
        .then(() => {
          this.campaign.max_cpc = this.newMaxCpcForm.amount ? parseFloat(this.newMaxCpcForm.amount) : 0;
          this.newMaxCpcForm.amount = '';
          this.editMaxCpc = false;
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    show(campaignId) {
      this.loading = true;
      this.campaign = null;
      this.newBudgetForm.campaignId = campaignId;
      this.newMaxCpcForm.campaignId = campaignId;
      this.modal.show();

      this.axios.get('/google/ad-details', {
          params: {
            campaignId: campaignId
          }
        })
        .then((res) => {
          this.campaign = res.data;
          this.loading = false;
        });
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>