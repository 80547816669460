<template>
  <div class="modal fade"
       id="modal-influencer-campaign-details"
       role="dialog"
       data-bs-focus="false"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-facebook-campaign-detailsLabel">Campaign Attributes </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="statbox widget box p-0" v-if="campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0" >
                  <tbody>
                  <tr>
                    <th>Campaign Name</th>
                    <td>{{ removeUnderscore(campaign.amazon_campaign) }}</td>
                  </tr>
                  <tr>
                    <th>ASIN</th>
                    <td>{{ campaign.influencer_asin }}</td>
                  </tr>
                  <tr>
                    <th>Spend</th>
                    <td>{{ amount }}</td>
                  </tr>
                  <tr v-if="campaign.influencer_campaign_url">
                    <th>Link</th>
                    <td>
                      {{ getFullLink }}
                      <div class="row">
                        <div class="col-12 text-right">
                          <a class="mr-3"
                             v-if="!campaign.influencer_short_link"
                             href=""
                             @click.prevent="generateShortLink('influencer_short_link')">
                            <i class="fas fa-link"></i> Generate Short Link
                          </a>

<!--                          <a class="mr-3"
                             v-if="!campaign.influencer_short_link_with_amazon_tag && settings && settings.amazon_associate_tag"
                             href=""
                             @click.prevent="generateShortLink('influencer_short_link_with_amazon_tag')">
                            <i class="fas fa-link"></i> Generate Short Link With Amazon Tag
                          </a>-->

                          <a href=""
                             @click.prevent="copyLink">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="campaign.influencer_short_link">
                    <th>Short Link</th>
                    <td>
                      <div class="row">
                        <div class="col-6">{{ campaign.influencer_short_link }}</div>
                        <div class="col-6 text-right">
                          <a href=""
                             @click.prevent="copyLink(campaign.influencer_short_link)">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
<!--                  <tr v-if="campaign.influencer_short_link_with_amazon_tag">
                    <th>Short Link With Amazon Tag</th>
                    <td>
                      <div class="row">
                        <div class="col-6">{{ campaign.influencer_short_link_with_amazon_tag }}</div>
                        <div class="col-6 text-right">
                          <a href=""
                             @click.prevent="copyLink(campaign.influencer_short_link_with_amazon_tag)">
                            <i class="fas fa-copy"></i> Copy
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>-->
                  <tr>
                    <th>Shared With</th>
                    <td>
                      <span class="badge badge-info mr-2"
                            v-for="email in campaign.influencer_emails"
                            :key="'email_'+email.id">
                        {{ email.email }}

                        <a class="text-danger"
                           @click.prevent="removeEmail(email.id)"
                           href="#">X</a>
                      </span>

                      <div class="row">
                        <div class="col-12 text-right">
                          <a href=""
                             @click.prevent="addEmail">
                            <i class="fas fa-plus"></i> Add/Share
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getSymbolFromCurrency from "currency-symbol-map";
import {mapGetters} from "vuex";

export default {
  props: ['currentCurrencyCode'],
  data() {
    return {
      modal: null,
      campaign: null,
      amount: '',
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    }),
    getFullLink() {
      if (this.campaign) {
        if (this.campaign.influencer_full_link) {
          return this.campaign.influencer_full_link;
        } else {
          return "https://www.amazon.com/dp/" + this.campaign.influencer_asin + this.campaign.influencer_campaign_url;
        }
      }

      return "";
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-influencer-campaign-details'));
  },
  methods: {
    generateShortLink(column) {
      let url = this.getFullLink;

      if (column === 'influencer_short_link_with_amazon_tag') {
        url += '&tag=' + this.settings.amazon_associate_tag;
      }
      this.axios.post('/short-link/generate', {
        amazon_ad: this.campaign.id,
        column: column,
        url: url
      })
        .then(res => {
          this.campaign[column] = res.data.data[column];
        })
    },
    copyLink(link = null) {
      let url = '';

      if (link)
        url = link;
      else
        url = "https://www.amazon.com/dp/" + this.campaign.influencer_asin + this.campaign.influencer_campaign_url;

      navigator.clipboard.writeText(url);
      this.showSuccessMsg("Link Copied!")
    },
    async addEmail() {
      const {value: email} = await this.$swal({
        title: 'Input email address',
        input: 'text',
        inputPlaceholder: 'Email'
      })

      if (email) {
        this.axios.post('/influencer/email/' + this.campaign.id, {
          email: email
        })
          .then(() => {
            this.loadCampaign(this.campaign.id)
          })
      }
    },
    removeEmail(id) {
      this.$swal({
        title: 'Are you sure want to remove?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes, remove it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLoading = true;

          this.axios.delete('/influencer/email/' + id)
            .then(() => {
              this.loadCampaign(this.campaign.id)
            });
        }
      });
    },
    loadCampaign(campaignId) {
      this.axios.get('/influencer/ad-details', {
        params: {
          campaignId: campaignId
        }
      })
        .then(async res => {
          this.campaign = res.data

          if (this.campaign.influencer_amount) {
            await this.axios.get('/currency/convert/' + (this.campaign.influencer_currency) + '/' + this.currentCurrencyCode)
              .then(res => {
                this.amount = getSymbolFromCurrency(this.currentCurrencyCode) + (this.campaign.influencer_amount * res.data)
              })
          }

          if (this.campaign.influencer_percentage) {
            if (this.campaign.influencer_amount) {
              this.amount += ' plus ';
            }

            this.amount += this.campaign.influencer_percentage + '% of revenue'
          }
        });
    },
    show(campaignId) {
      this.modal.show();

      this.loadCampaign(campaignId);
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>