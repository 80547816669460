<template>
  <div class="box box-shadow bg-white mt-4 p-3 pb-4 rounded">
    <div class="row">
      <div class="col-md-3">
        <div class="input-group mb-md-0 mb-1">
          <input type="text"
                 id="reportrange"
                 :disabled="loading"
                 class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">
              <i class="flaticon-calendar"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <select class="form-control"
                ref="dashboardAdAccount"
                v-model="queryParams.profileId"
                :disabled="loading"
                @change="changeAccount($event.target.value)">
          <option value="" default selected>Select Ad Account</option>
          <option :value="account.profileId"
                  v-for="account in amazonAdAccounts"
                  :key="'ac_'+account.profileId">
            {{ account.accountInfo.name }} ({{ account.currencyCode }})
          </option>
        </select>
      </div>

      <div class="col-md-2">
        <input type="text"
               v-model="search"
               class="form-control"
               placeholder="Search">
      </div>

      <div class="col-md-2">
        <div class="btn-group w-100 w-md-auto">
          <button type="button" class="btn btn-primary">Export</button>
          <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
            <a class="dropdown-item" href="#" @click.prevent="downloadExcel('csv')">CSV</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click.prevent="downloadExcel('xlsx')">XLSX</a>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="custom-control custom-checkbox mb-md-0">
          <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="showAcosAfterBrb">
          <label class="custom-control-label text-black mb-0" for="customCheck1">Show ACoS after Brand Referral Bonus</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-if="errorMsg">
    <div class="col-md-12">
      <div class="alert alert-danger border-0" role="alert">
        {{ errorMsg }}
      </div>
    </div>
  </div>

  <!-- STATISTICS BOX BEGIAN -->
  <div class="row layout-spacing"  v-if="!loading && campaigns.length">
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area  data-widgets br-4">
        <div class="widget  t-sales-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-dollar-coin"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">Spend</p>
              <p class="widget-numeric-value">{{ this.currentCurrency }} {{ totalSpends.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area  data-widgets br-4">
        <div class="widget  t-order-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-stats"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">Impressions</p>
              <p class="widget-numeric-value">{{ totalImpressions.toLocaleString() }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area  data-widgets br-4">
        <div class="widget  t-customer-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-3d-cube"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">Clicks</p>
              <p class="widget-numeric-value">{{ totalClicks.toLocaleString() }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area  data-widgets br-4">
        <div class="widget  t-income-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-money"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">CPC</p>
              <p class="widget-numeric-value">{{ this.currentCurrency }} {{ totalCpc.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area data-widgets br-4">
        <div class="widget t-conv-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-cart-1 primary"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">Conversions</p>
              <p class="widget-numeric-value">{{ totalPurchase.toLocaleString() }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area  data-widgets br-4">
        <div class="widget t-rev-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-chart-3"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">Revenue</p>
              <p class="widget-numeric-value">{{ this.currentCurrency }} {{ totalRevenue.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area  data-widgets br-4">
        <div class="widget  t-acos-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-bank-safe-box"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">ACoS</p>
              <p class="widget-numeric-value">{{ calculateACoS(totalRevenue, totalSpends, totalBrb).toLocaleString(undefined, {minimumFractionDigits: 2}) }}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 mb-xl-2 col-lg-6 col-md-6 col-sm-6 mb-1">
      <div class="widget-content-area data-widgets br-4">
        <div class="widget t-raocs-widget">
          <div class="media">
            <div class="icon ml-2">
              <i class="flaticon-user-11"></i>
            </div>
            <div class="media-body text-right">
              <p class="widget-text mb-0">TACoS</p>
              <p class="widget-numeric-value">Coming Soon</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- STATISTICS BOX BEGIAN -->


  <!-- STATISTICS TABLE BEGIAN -->
  <div class="row layout-spacing">
    <div class="col-lg-12">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area rounded" >
          <div class="text-center" v-if="loading">
            <div class="spinner-border" role="status"></div>
          </div>

          <div class="row" v-else-if="campaigns.length">
            <div class="col-lg-12">
              <div class="table-responsive">
                <table id="table2" class="tablesaw table-striped" data-tablesaw-mode="swipe" data-tablesaw-sortable data-tablesaw-sortable-switch data-tablesaw-minimap data-tablesaw-mode-switch>
                  <thead>
                    <tr>
                      <th scope="col" data-tablesaw-sortable-col data-tablesaw-sortable-default-col data-tablesaw-priority="persist">Amazon Campaign</th>
                      <th></th>
                      <th scope="col" data-tablesaw-sortable-col @click="sortTable('spend')">
                        Spend
                        <span v-if="this.table.sortColumn === 'spend'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col @click="sortTable('impressions')">
                        Impressions
                        <span v-if="this.table.sortColumn === 'impressions'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col @click="sortTable('clicks')">
                        Clicks

                        <span v-if="this.table.sortColumn === 'clicks'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col @click="sortTable('cpc')">
                        CPC

                        <span v-if="this.table.sortColumn === 'cpc'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col >CTR</th>
                      <th scope="col" data-tablesaw-sortable-col  @click="sortTable('atc')">
                        Add To Cart

                        <span v-if="this.table.sortColumn === 'atc'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col  @click="sortTable('total_purchase')">
                        Conversions

                        <span v-if="this.table.sortColumn === 'total_purchase'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col  @click="sortTable('total_sale')">
                        Revenue
                        <span v-if="this.table.sortColumn === 'total_sale'">
                                                <i class="fas fa-sort-amount-down-alt"
                                                  aria-hidden="true"
                                                  v-if="this.table.sortOrder === 'asc'"></i>

                                                <i class="fas fa-sort-amount-down"
                                                  aria-hidden="true" v-else></i>
                                              </span>
                      </th>
                      <th scope="col" data-tablesaw-sortable-col >ACoS</th>
                      <th scope="col" data-tablesaw-sortable-col >Brand Referral Bonus</th>
                      <th scope="col" data-tablesaw-sortable-col class="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  <template v-for="(campaign, i) in sortedCampaigns" :key="'campaign_'+i">
                    <tr>
                      <td v-if="campaign.google_campaign_name">
                        <router-link :to="{name: 'keywords_google', params: {id: campaign.google_campaign, profileId: queryParams.profileId}}">
                          {{ formatCampaignName(campaign.name) }}
                        </router-link>
                      </td>
                      <td v-else>{{ removeUnderscore(campaign.name) }}</td>
                      <td class="no-wrap text-right">

                        <router-link class="mr-1" v-if="campaign.google_campaign_name" :to="{name: 'keywords_google', params: {id: campaign.google_campaign, profileId: queryParams.profileId}}">
                          <img :src="baseUrl + 'images/keyword.png'" style="height: 17px">
                        </router-link>

                        <a href="#"
                           v-if="campaign.fb_campaign_name"
                           @click.prevent="getFacebookAdDetails(campaign)">
                          <i class="text-blue fab fa-facebook tbl-social-icon"></i>
                        </a>


                        <a href="#" v-if="campaign.google_campaign_name" @click="getGoogleAdDetails(campaign)">
                          <img :src="baseUrl + 'images/google.png'" style="height: 17px">
                        </a>
                        <a href="#"
                           v-if="campaign.influencer_name"
                           @click="getInfluencerAdDetails(campaign)">
                           <img :src="baseUrl + 'images/infu.png'" style="height: 17px">
                        </a>
                      </td>
                      <td class="no-wrap">
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>
                          {{ this.currentCurrency }} 
                          <span v-if="campaign.influencer_amount">{{ campaign.influencer_amount.toFixed(2) }}</span>
                          <span v-else-if="campaign.influencer_percentage">{{ campaign.influencer_percentage.toFixed(2) }}</span>
                          <span v-else>{{ campaign.spend.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
                        </span>
                      </td>
                      <td>
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else-if="campaign.influencer_name">-</span>
                        <span v-else>{{ campaign.impressions.toLocaleString() }}</span>
                      </td>
                      <td>
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ campaign.clicks.toLocaleString() }}</span>
                      </td>
                      <td class="no-wrap">
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ this.currentCurrency }}
                          <span v-if="campaign.influencer_name">{{ campaign.clicks ? (campaign.influencer_amount / campaign.clicks).toFixed(2) : 0 }}</span>
                          <span v-else>{{ campaign.cpc ? campaign.cpc.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0 }}</span>
                        </span>
                      </td>
                      <td>
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else-if="campaign.influencer_name">-</span>
                        <span v-else>{{ campaign.impressions ? ((campaign.clicks * 100 ) / campaign.impressions).toFixed(2) : 0 }}%</span>
                      </td>
                      <td>
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ campaign.atc.toLocaleString() }}</span>
                      </td>
                      <td>
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ campaign.total_purchase.toLocaleString() }}</span>
                      </td>
                      <td class="no-wrap">
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ this.currentCurrency }} {{ campaign.total_sale.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
                      </td>
                      <td>
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ calculateACoS(campaign.total_sale, campaign.spend, campaign.brb).toLocaleString(undefined, {minimumFractionDigits: 2}) }}%</span>
                      </td>
                      <td class="no-wrap">
                        <div class="spinner-border spinner-border-sm"
                             v-if="loadingMetrics"
                             role="status"></div>
                        <span v-else>{{ this.currentCurrency }} {{ campaign.brb.toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group m-h-34" v-if="campaign.influencer_name">
                          <button type="button" class="btn btn-sm w-80 btn-success">ACTIVE</button>
                          <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split btn-success" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                            <a class="dropdown-item"
                               v-if="account && account.role !== 3"
                               href="#"
                               @click.prevent="removeCampaign(campaign)">Unlink</a>
                          </div>
                        </div>
                        <div class="btn-group m-h-34" v-if="campaign.status && campaign.google_campaign_name">
                          <button type="button" class="btn btn-sm w-80" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]">{{ campaign.status.status == 'active' ? 'ENABLED' : campaign.status.status }}</button>
                          <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                            <a class="dropdown-item"
                               @click.prevent="changeGoogleStatus(2, campaign)"
                               href="#" >Enable</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item"
                               @click.prevent="changeGoogleStatus(3, campaign)"
                               href="#">Pause</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" @click.prevent="removeCampaign(campaign)">Unlink</a>
                          </div>
                        </div>
                        <div class="btn-group m-h-34" v-if="campaign.status && campaign.fb_campaign_name">
                          <button type="button" class="btn btn-sm w-80" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]">{{campaign.status.status }}</button>
                          <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" :class="[ campaign.status.status == 'PAUSED' ? 'btn-warning' : 'btn-success'  ]" id="dropdownMenuReference4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                            <span class="sr-only">Toggle Dropdown</span>
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuReference4">
                            <a class="dropdown-item"
                               @click.prevent="changeFacebookStatus('ACTIVE', campaign)"
                               href="#" >Active</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item"
                               @click.prevent="changeFacebookStatus('PAUSED', campaign)"
                               href="#">Pause</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item"
                               v-if="account && account.role !== 3"
                               href="#"
                               @click.prevent="removeCampaign(campaign)">Unlink</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-else>No information here yet…
            <router-link class="text-blue" :to="{name: 'google_wizard'}" v-if="settings.google_user_id">Create a campaign</router-link>
            <router-link class="text-blue" :to="{name: 'create_campaign_facebook'}" v-else>Create a campaign</router-link>
            or
            <router-link class="text-blue" :to="{name: 'mapping'}">Link a campaign</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- STATISTICS TABLE BEGIAN -->

  <google-campaign-modal ref="googleCampaignModal" />
  <facebook-campaign-modal ref="facebookCampaignModal" />
  <influencer-campaign-modal :currentCurrencyCode="currentCurrencyCode"
                             ref="influencerCampaignModal" />
</template>

<script>
import GoogleCampaignModal from "./modals/GoogleCampaignModal";
import FacebookCampaignModal from "./modals/FacebookCampaignModal";
import InfluencerCampaignModal from "./modals/InfluencerCampaignModal";
import {mapGetters} from "vuex";
import getSymbolFromCurrency from 'currency-symbol-map'

export default {
  components: {GoogleCampaignModal, FacebookCampaignModal, InfluencerCampaignModal},
  data() {
    return {
      search: '',
      table: {
        sortColumn: 'impressions',
        sortOrder: 'desc',
      },
      loadingMetrics: false,
      campaigns: [],
      showAcosAfterBrb: false,
      errorMsg: null,
      queryParams: {
        onlyMapped: 1,
        profileId: '',
        startDate: window.moment().subtract(3, "years").startOf("month").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
      account: 'auth/getAccount',
      settings: 'settings/getSettings'
    }),
    currentCurrencyCode() {
      let amazonAccount = this.amazonAdAccounts.find(a => a.profileId == this.queryParams.profileId);
      return amazonAccount ? amazonAccount.currencyCode : null;
    },
    currentCurrency() {
      let amazonAccount = this.amazonAdAccounts.find(a => a.profileId == this.queryParams.profileId);
      return amazonAccount ? getSymbolFromCurrency(amazonAccount.currencyCode) : null;
    },
    sortedCampaigns() {
      let c = [...this.campaigns];

      if (this.search) {
        c = c.filter(campaign => this.getFormattedCampaignName(campaign).toLowerCase().includes(this.search.toLowerCase()));
      }

      if (this.table.sortOrder === 'asc')
        return c.sort((a,b) => a[this.table.sortColumn] - b[this.table.sortColumn]);
      else
        return c.sort((a,b) => b[this.table.sortColumn] - a[this.table.sortColumn]);
    },
    totalBrb() {
      return this.campaigns.reduce((total, campaign) => total + campaign.brb, 0);
    },
    totalImpressions() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.impressions;
      });

      return total;
    },
    totalClicks() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.clicks;
      })

      return total;
    },
    totalCpc() {
      let totalSpends = 0;
      let totalClicks = 0;

      this.campaigns.forEach(campaign => {
        totalSpends += !isNaN(campaign.spend) ? campaign.spend : 0;
        totalClicks += !isNaN(campaign.clicks) ? campaign.clicks : 0;
      })

      if (!totalClicks)
        return 0;

      return totalSpends / totalClicks;
    },
    totalSpends() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        if (campaign.spend !== '')
          total += campaign.spend;
      })

      return total;
    },
    totalRevenue() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.total_sale;
      })

      return total;
    },
    totalPurchase() {
      let total = 0;

      if (this.loadingMetrics)
        return total;

      this.campaigns.forEach(campaign => {
        total += campaign.total_purchase;
      })

      return total;
    }
  },
  watch: {
    settings: {
      handler(val) {
        if (val.amazon_profile_id) {
          setTimeout(() => {
            this.$refs.dashboardAdAccount.value = val.amazon_profile_id;
            this.queryParams.profileId = val.amazon_profile_id;
            this.loadData()
          }, 50)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.settings.amazon_profile_id) {
      this.$refs.dashboardAdAccount.value = this.settings.amazon_profile_id;
      this.queryParams.profileId = this.settings.amazon_profile_id;
      this.loadData()
    }

    let t = window.moment().subtract(3, "years").startOf("month");
    let a = window.moment();
    let self = this;

    window.$("#reportrange").daterangepicker({
        startDate: t,
        endDate: a,
        locale: {
          format: 'MMM D, YYYY'
        },
        ranges: {
          Today: [window.moment(), window.moment()],
          Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
          "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
          "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
          "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
          "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
          "All Time": [window.moment().subtract(3, "years").startOf("month"), window.moment()],
        },
      },
      function (t, a) {
        self.queryParams.startDate = t.format('YYYYMMDD');
        self.queryParams.endDate = a.format('YYYYMMDD');
        self.loadData();
      }
    )
  },
  methods: {
    sortTable(col) {
      let order = 'asc';

      if (col === this.table.sortColumn) {
        if (this.table.sortOrder === 'asc')
          order = 'desc';
      }

      this.table.sortColumn = col;
      this.table.sortOrder = order;
    },
    getFacebookAdDetails(campaign) {
      this.$refs.facebookCampaignModal.show(campaign.fb_campaign_id);
    },
    getGoogleAdDetails(campaign) {
      this.$refs.googleCampaignModal.show(campaign.google_campaign);
    },
    getInfluencerAdDetails(campaign){
      this.$refs.influencerCampaignModal.show(campaign.id);
    },
    changeGoogleStatus(status, campaign) {
      this.axios.post('/google/change/status', {
        status: status,
        customerId: campaign.google_account_id,
        campaignId: campaign.google_campaign
      })

      setTimeout(() => {
        campaign.status = this.getGoogleCampaignStatus(status);
      }, 100)
    },
    changeFacebookStatus(status, campaign) {
      this.axios.post('/facebook/change/status', {
        status: status,
        campaignId: campaign.fb_campaign_id
      })

      setTimeout(() => {
        campaign.status = this.getFacebookCampaignStatus(status);
      }, 100)
    },
    removeCampaign(campaign) {
      this.$swal({
        title: 'Are you sure want to unlink?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, unlink it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/remove-campaign/', {
            name: campaign.name
          }).then(() => {
            campaign.google_campaign = null;
            campaign.google_campaign_name = null;
            campaign.fb_campaign_id = null;
            campaign.fb_campaign_name = null;
            this.$swal.fire(
              'Unlinked',
              'Campaign has been unlinked!',
              'success'
            )
            this.loadData()
          });
        }
      });
    },
    calculateACoS(purchase, spend, brb) {
      if (purchase && !isNaN(spend / purchase)) {

        if (this.showAcosAfterBrb)
          spend -= brb;

        return ((spend / purchase) === Infinity ? 0 : (spend / purchase) * 100).toFixed(2);
      } else
        return 0;
    },
    downloadExcel(type) {
      this.axios.post('/ads/amazon/campaign/excel', {
        data: this.campaigns,
        type: type
      }, {
        responseType: "arraybuffer",
      }).then((response) => {
        const blob = new Blob([response.data], { type: type === 'csv' ? "text/csv" : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "report." + type;
        link.click();
      });
    },
    changeAccount(profile) {
      this.campaigns = [];
      this.queryParams.profileId = profile;

      this.$store.dispatch('settings/saveSettings', {
        amazon_profile_id: profile
      })
    },
    checkMetrics() {
      let googleAccounts = [];
      let fbAccounts = [];

      this.campaigns.forEach(campaign => {
        if (campaign.fb_campaign_id) {
          if (!fbAccounts.includes(campaign.fb_account_id))
            fbAccounts.push(campaign.fb_account_id);
        } else if (campaign.google_campaign) {
          if (!googleAccounts.includes(campaign.google_account_id))
            googleAccounts.push(campaign.google_account_id);
        }
      })

      let reqs = [];

      if (fbAccounts.length) {
        reqs.push(
          this.axios.post('/fb-campaign-spend', {
              accounts: fbAccounts,
              currency: this.currentCurrencyCode,
              start: this.queryParams.startDate,
              end: this.queryParams.endDate,
            })
            .then(res => {
              res.data.forEach(c => {
                let campaigns = this.campaigns.filter(cam => cam.fb_campaign_id == c.campaign_id);

                campaigns.forEach(campaign => {
                  campaign.impressions = parseInt(c.impressions);
                  campaign.clicks = parseInt(c.clicks);
                  campaign.spend = parseFloat(c.spend);

                  if (campaign.clicks)
                    campaign.cpc = campaign.spend / campaign.clicks;
                });
              })

              this.campaigns.forEach(campaign => {
                if (campaign.fb_campaign_id && campaign.spend === '') {
                  campaign.spend = 0;
                }
              })
            })
        );

        reqs.push(
          this.axios.post('/fb-campaign-status', {
              accounts: fbAccounts
            })
            .then(res => {
              res.data.forEach(c => {
                let campaign = this.campaigns.find(cam => cam.fb_campaign_id == c.id);

                if (campaign) {
                  campaign.status = this.getFacebookCampaignStatus(c.status)
                }
              })
            })
        )
      }

      if (googleAccounts.length) {
        reqs.push(
          this.axios.post('/google-campaign-spend', {
              accounts: googleAccounts,
              currency: this.currentCurrencyCode,
              start: this.queryParams.startDate,
              end: this.queryParams.endDate,
            })
            .then(res => {
              res.data.forEach(c => {
                let campaigns = this.campaigns.filter(cam => cam.google_campaign == c.id);

                campaigns.forEach(campaign => {
                  campaign.impressions = c.impressions;
                  campaign.clicks = c.clicks;
                  campaign.cpc = c.cpc;
                  campaign.spend = c.cost;
                  campaign.status = this.getGoogleCampaignStatus(c.status);
                })
              })
            })
        )
      }

      Promise.all(reqs).finally(() => this.loadingMetrics = false)
    },
    loadData() {
      this.loading = true;
      this.campaigns = [];
      this.errorMsg = null;
      this.loadingMetrics = true;

      this.axios.get('ads/amazon/campaign', {
          params: this.queryParams
        })
        .then(response => {
          this.campaigns = response.data.data;

          this.axios.get('/ads/amazon/new-campaign')
            .then(response => {
              let campaigns = response.data.data.filter(cam => !this.campaigns.find(c => c.name === cam.name))
                .map(cam => {
                  return {
                    id: cam.id,
                    atc: 0,
                    brb: 0,
                    click_through: 0,
                    clicks: 0,
                    dpv: 0,
                    fb_campaign_id: cam.fb_campaign,
                    fb_campaign_name: cam.fb_campaign_name,
                    google_campaign: cam.google_campaign,
                    google_campaign_name: cam.google_campaign_name,
                    google_account_id: cam.google_account_id,
                    google_manager_id: cam.google_manager_id,
                    fb_account_id: cam.fb_account_id,
                    influencer_name: cam.influencer_name,
                    influencer_amount: cam.influencer_amount,
                    influencer_percentage: cam.influencer_percentage,
                    influencer_spend: cam.influencer_spend,
                    influencer_asin: cam.influencer_asin,
                    influencer_campaign_url: cam.influencer_campaign_url,
                    impressions: 0,
                    name: cam.name,
                    spend: 0,
                    total_purchase: 0,
                    total_sale: 0,
                  };
                })

              this.campaigns = [...this.campaigns, ...campaigns];

              this.checkMetrics();
            })
        })
        .catch(err => {
          this.errorMsg = err.response.data.message
        })
        .finally(() => this.loading = false)

    },
  }
}
</script>

<style>

.tablesaw tbody tr,
.tablesaw tbody tr td{
  height: 58px;
  min-height: 58px;
}

.m-h-34{
  min-height: 34px;
}

.tbl-social-icon {
  font-size: 17px;
  position: relative;
  top: 3px;
}

.tablesaw th {
  padding: 17px 0.7em;
}
.row.layout-spacing {
  margin: 0 -5px;
}
.row.layout-spacing {
  margin-bottom: 20px !important;
  margin-top: 10px;
}
.row.layout-spacing > div {
  padding: 0px 5px;
}

.w-80{
  width: 80px;
}

@media screen and (min-width: 1100px){
  .w-md-auto{
    width: auto !important
  }
}

</style>