<template>
  <page-title-component title="Link Existing Campaigns"
                        subTitle="Tell us where your existing campaigns are advertised to see them in the dashboard">
  </page-title-component>

  <div class="box box-shadow bg-white mb-4 p-3 rounded">
    <div class="row">
      <div class="col-4">
        <select class="form-select form-control"
                ref="linkAdAccount"
                @change="changeAccount($event.target.value)">
          <option value="">Select Ad Account</option>
          <option :value="account.profileId"
                  v-for="account in amazonAdAccounts"
                  :key="'ac_'+account.profileId">{{ account.accountInfo.name }} ({{ account.currencyCode }})</option>
        </select>
      </div>
      <div class="col-4">
        <div class="input-group">
          <input type="text"  id="reportrange" class="form-control" value="10/24/1984" />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"><i class="flaticon-calendar"></i></span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="input-group">
          <input type="text" class="form-control" v-model="search">
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"><i class="flaticon-search"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" v-if="errorMsg">
    <div class="col-md-12">
      <div class="alert alert-danger border-0" role="alert">
        {{ errorMsg }}
      </div>
    </div>
  </div>


  <!-- STATISTICS TABLE BEGIAN -->
  <div class="row layout-spacing" v-if="!errorMsg">
    <div class="col-lg-12">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area" >
          <div class="text-center" v-if="loading">
            <div class="spinner-border" role="status"></div>
          </div>

          <div class="row" v-else>
            <div class="col-lg-12">
              <p>Only shows campaigns with over 10 clicks or a sale</p>
              <div class="table-responsive">
                <table id="table2" class="tablesaw table-striped" data-tablesaw-mode="swipe" data-tablesaw-sortable data-tablesaw-sortable-switch data-tablesaw-minimap data-tablesaw-mode-switch>

                  <thead>
                  <tr>
                    <th scope="col" data-tablesaw-sortable-col data-tablesaw-sortable-default-col data-tablesaw-priority="persist">Campaign Name</th>
                    <th></th>
                    <th scope="col" data-tablesaw-sortable-col >Mapped Campaign</th>
                    <th scope="col" data-tablesaw-sortable-col class="text-right">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(campaign, i) in filterCampaigns" :key="'campaign_'+i">
                    <tr :class="{'table-info': activeCampaignIndex === i}">
                      <td>{{ campaign.name }}</td>
                      <td></td>
                      <td v-if="campaign.fb_campaign_name">
                        <i class="text-blue fab fa-facebook-square"></i> {{ campaign.fb_campaign_name }}
                      </td>
                      <td v-else-if="campaign.google_campaign_name">
                        <i class="text-danger fab fa-google"></i> {{ campaign.google_campaign_name }}
                      </td>
                      <td v-else-if="campaign.influencer_name">
                        <img :src="baseUrl + 'images/infu.png'" style="height: 17px">
                        {{ campaign.influencer_name }}
                      </td>
                      <td v-else></td>
                      <td class="text-right">
                        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="" data-original-title="Remove" @click.prevent="removeCampaign(campaign)"
                           v-if="campaign.fb_campaign_name || campaign.google_campaign_name || campaign.influencer_name">
                          <i class="far fa-times-circle bg-danger p-2 text-white br-6 mb-1"></i>
                        </a>
                        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" v-else
                           @click.prevent="changeCampaign(campaign)">
                          <i class="flaticon-edit bg-warning p-2 text-white br-6 mb-1"></i>
                        </a>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- STATISTICS TABLE BEGIAN -->


  <campaign-modal @campaignUpdate="modalCampaignUpdate"
                  :amazonCampaign="selectedCampaign">
  </campaign-modal>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import CampaignModal from "@/views/advertise/CampaignModal";
import {mapGetters} from "vuex";

export default {
  components: {PageTitleComponent, CampaignModal},
  data() {
    return {
      campaignModal: null,
      selectedCampaign: null,
      facebookCampaigns: [],
      campaigns: [],
      search: '',
      errorMsg: null,
      queryParams: {
        profileId: '',
        startDate: window.moment().subtract(6, "months").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
      settings: 'settings/getSettings',
    }),
    filterCampaigns() {
      if (this.search) {
        return this.campaigns.filter(campaign => {
          let name = (campaign.name + '').toLowerCase();

          return name.includes(this.search.toLowerCase());
        })
      } else {
        return this.campaigns;
      }
    }
  },
  watch: {
    amazonAdAccounts: {
      handler() {
        if (this.settings.amazon_profile_id) {
          setTimeout(() => {
            this.$refs.linkAdAccount.value = this.settings.amazon_profile_id;
          }, 100);

        }
      },
      deep: true
    },
    settings: {
      handler(val) {
        if (val.amazon_profile_id) {
          this.$refs.linkAdAccount.value = val.amazon_profile_id;
          this.queryParams.profileId = val.amazon_profile_id;
          this.loadData()
        }
      },
      deep: true
    }
  },
  created() {
    // this.loadData();
    // this.loadFbCampaigns();
  },
  mounted() {
    if (this.settings.amazon_profile_id) {
      this.$refs.linkAdAccount.value = this.settings.amazon_profile_id;
      this.queryParams.profileId = this.settings.amazon_profile_id;
      this.loadData()
    }

    let t = window.moment().subtract(6, "months");
    let a = window.moment();
    let self = this;

    this.campaignModal = new window.bootstrap.Modal(document.getElementById('campaign-modal'));

    window.$("#reportrange").daterangepicker({
        startDate: t,
        endDate: a,
        locale: {
          format: 'MMM D, YYYY'
        },
        ranges: {
          Today: [window.moment(), window.moment()],
          Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
          "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
          "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
          "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
          "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
          "All Time": [window.moment().subtract(15, "years").startOf("month"), window.moment()],
        },
      },
      function (t, a) {
        self.queryParams.startDate = t.format('YYYYMMDD');
        self.queryParams.endDate = a.format('YYYYMMDD');
        self.loadData();
      }
    )
  },
  methods: {
    changeAccount(profile) {
      this.campaigns = [];
      this.queryParams.profileId = profile;

      if (profile) {
        this.loadData();
      }
    },
    modalCampaignUpdate(data) {
      this.campaignModal.hide();

      if (data.fb_campaign && data.fb_campaign !== "") {
        this.selectedCampaign.google_campaign = null;
        this.selectedCampaign.google_campaign_name = null;
        this.selectedCampaign.fb_campaign_id = data.fb_campaign;
        this.selectedCampaign.fb_campaign_name = data.fb_campaign_name;
      }

      if (data.google_campaign && data.google_campaign !== "") {
        this.selectedCampaign.fb_campaign_id = null;
        this.selectedCampaign.fb_campaign_name = null;
        this.selectedCampaign.google_campaign = data.google_campaign;
        this.selectedCampaign.google_campaign_name = data.google_campaign_name;
      }

      this.selectedCampaign.spend = '';

      // this.getFbSpend(this.selectedAdGroup);
      this.loadData();
    },
    getFacebookCampaignName(adGroup) {
      if (adGroup.fb_campaign_id) {
        let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

        if (fbCampaign)
          return fbCampaign.name;
        else
          return '';
      } else {
        return ''
      }
    },
    getFacebookCampaignCost(adGroup) {
      if (adGroup.fb_campaign_id) {
        let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

        if (fbCampaign)
          return '$'+(parseFloat(fbCampaign.lifetime_budget) / 100).toFixed(2);
        else
          return '';
      } else {
        return ''
      }
    },
    changeCampaign(campaign) {
      this.selectedCampaign = campaign;
      this.campaignModal.show();
    },
    removeCampaign(campaign) {
      this.$swal({
        title: 'Are you sure want to unlink?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, unlink it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/remove-campaign/', {
            name: campaign.name
          }).then(() => {
            campaign.google_campaign = null;
            campaign.google_campaign_name = null;
            campaign.fb_campaign_id = null;
            campaign.fb_campaign_name = null;
            this.$swal.fire(
              'Unlinked',
              'Campaign has been unlinked!',
              'success'
            )
            this.loadData()
          });
        }
      });
    },
    changeCampaignIndex(index) {
      if (this.activeCampaignIndex === index)
        this.activeCampaignIndex = null;
      else {
        this.activeCampaignIndex = index

        /*this.campaigns[this.activeCampaignIndex].ad_groups.forEach(adGroup => {
          this.getFbSpend(adGroup);
        })*/
      }
    },
    loadFbCampaigns() {
      this.axios.get('/facebook/campaigns')
        .then(response => {
          this.facebookCampaigns = response.data.data;
        })
    },
    loadData() {
      this.loading = true;
      this.campaigns = [];
      this.errorMsg = null;

      this.axios.get('ads/amazon/campaign', {
        params: this.queryParams
      })
        .then(response => {
          this.campaigns = response.data.data

        })
        .catch(err => {
          this.errorMsg = err.response.data.message
        })
        .finally(() => this.loading = false)
    },
    getFbSpend(adGroup) {
      let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

      if (fbCampaign) {
        this.axios.post('/fb-campaign-spend', {
          campaign_id: fbCampaign.id,
          startDate: this.queryParams.startDate,
          endDate: this.queryParams.endDate,
        }).then(response => {
          if (response.data.data.length) {
            if (response.data.data[0].spend) {
              adGroup.spend = '$' + response.data.data[0].spend;
            } else {
              adGroup.spend = '$0';
            }
          }
        })
      } else {
        adGroup.spend = '$0';
      }
    }
  }
}
</script>