<template>
  <div class="modal fade" id="modal-facebook-campaign-details" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-facebook-campaign-detailsLabel">Campaign Attributes </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="loading" class="d-flex items-center justify-content-center w-100 py-5">
            <div class="spinner-border spinner-border-sm"></div>
          </div>
          <div class="statbox widget box p-0" v-else-if="!loading && campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0" >
                  <tbody>
                  <tr>
                    <th>Campaign Name</th>
                    <td>{{ campaign.fb_campaign_name }}</td>
                  </tr>
                  <tr>
                    <th>Campaign ID</th>
                    <td>{{ campaign.id }}</td>
                  </tr>
                  <tr>
                    <th>Lifetime Budget</th>
                    <td>
                      <span v-if="campaign.lifetime_budget">${{ (parseFloat(campaign.lifetime_budget) / 100).toFixed(2) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Campaign Start</th>
                    <td>{{ campaign.start_time }}</td>
                  </tr>
                  <tr>
                    <th>Campaign Status</th>
                    <td>{{ campaign.status }}</td>
                  </tr>
                  <tr>
                    <th>Bidding Strategy</th>
                    <td>{{ campaign.bid_strategy }}</td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
          <div v-else class="text-center py-5">
            <h2>No data found :)</h2>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      modal: null,
      campaign: null
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-facebook-campaign-details'))
  },
  methods: {
    show(campaignId) {
      this.loading = true;
      this.modal.show();
      this.axios.get('/facebook/ad-details', {
          params: {
            campaignId: campaignId
          }
        })
        .then((res) => {
          this.campaign = res.data;
          this.loading = false;
        });

    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>