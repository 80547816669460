<template>
  <div class="login w-100">
    <div class="form-login">
      <div class="row">

        <div class="col-md-12 text-center mb-4">
          <img :src="baseUrl + 'assets/images/logo.png'" alt="logo" class="auth-logo">
        </div>

        <div class="col-md-12">
          <form class="form-horizontal auth-form">
            <div class="mb-3">
              <label for="inputPassword" class="sr-only">New Password</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('password')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputPassword"><i class="flaticon-key-2"></i> </span>
                </div>
                <input type="password"
                       id="inputPassword"
                       class="form-control"
                       v-model="resetPasswordForm.password"
                       placeholder="New Password"
                       aria-describedby="inputPassword" required >
              </div>
              <v-errors :errors="errorFor('password')"></v-errors>
            </div>

            <div class="mb-3">
              <label for="inputPassword" class="sr-only">Confirm New Password</label>
              <div class="input-group mb-1" :class="{'is-invalid': errorFor('password_confirmation')}">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="icon-inputPassword"><i class="flaticon-key-2"></i> </span>
                </div>
                <input type="password"
                       id="inputPassword"
                       class="form-control"
                       v-model="resetPasswordForm.password_confirmation"
                       placeholder="Confirm New Password"
                       aria-describedby="inputPassword" required >
              </div>
              <v-errors :errors="errorFor('password_confirmation')"></v-errors>
            </div>

            <div class="form-group mb-0 row">
              <div class="col-12">
                <button class="btn btn-lg btn-gradient-warning btn-block btn-rounded mb-2 mt-2"
                        @click.prevent="resetPassword"
                        type="button">
                  Reset <i class="fas fa-sign-in-alt ms-1"></i>
                </button>
              </div><!--end col-->
            </div> <!--end form-group-->
          </form><!--end form-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resetPasswordForm: {
        password: '',
        email: this.$route.query.email,
        password_confirmation: '',
        token: this.$route.query.token
      }
    }
  },
  methods: {
    resetPassword() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/password/reset', this.resetPasswordForm)
        .then(() => {
          this.$router.push({name: 'login', query: {reset: 1}})
        })
        .catch((err) => this.allErrors = err.response.data.errors)
        .finally(() => this.loading = false);
    },
  }
}
</script>

<style scoped>

.auth-logo{
  max-width: 225px;
  margin-bottom: 58px;
}

.form-login .input-group.mb-1.is-invalid {
  border: 1px solid #fd7167;
  border-radius: 30px;
}

.form-login .form-control{
  caret-color: #fff;
}

::-webkit-input-placeholder,
::-ms-input-placeholder,
::placeholder{
  color: red !important;
  font-size: 16px;
  position: relative;
  box-sizing: border-box;
  height: auto;
  font-size: 16px;
  color: #fff;
}

input::placeholder {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: #fff !important;
}

.verify-screen-width {
  max-width: 530px;
}

</style>