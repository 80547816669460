<template>
  <page-title-component title="Settings"></page-title-component>
  <div id="amazon-root"></div>

  <div class="row">
    <div class="col-lg-6 col-md-6 layout-spacing" v-if="!isCurrentPlanLoading">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-title">
              <div class="p-caption">
                <span class="caption-subject text-uppercase">Connect</span>
              </div>
            </div>
            <div class="portlet-body py-4">
              <div class="d-grid gap-2">
                <button type="button"
                        v-if="settings && settings.amazon_user_id"
                        @click.prevent="socialLogout('amazon')"
                        class="social-btn amazon logout">
                  <div class="social-btn-icon">
                    <i class="fab fa-amazon me-2"></i>
                  </div>
                  Logout {{ settings.amazon_name }}
                </button>
                <a :href="amazonAuthUrl"
                   v-else
                   class="social-btn amazon connect">
                  <div class="social-btn-icon">
                    <i class="fab fa-amazon me-2"></i>
                  </div>
                  Connect To Amazon
                </a>
<!--                <a class="social-btn amazon connect"
                   v-if="settings"
                   @click.prevent="$refs.amazonAssociateModal.show(settings.amazon_associate_tag)"
                   href="#">
                  <div class="social-btn-icon">
                    <i class="fab fa-amazon me-2"></i>
                  </div>
                  Associate Tag
                </a>-->
                <button type="button"
                        v-if="settings && settings.fb_user_id"
                        @click.prevent="socialLogout('facebook')"
                        class="social-btn facebook logout">
                  <div class="social-btn-icon">
                    <i class="fab fa-facebook-f me-2"></i>
                  </div>
                  Logout {{ settings.fb_name }}
                </button>
                <button type="button"
                        v-else
                        @click.prevent="fbLogin"
                        class="social-btn facebook connect">

                  <div class="social-btn-icon">
                    <i class="fab fa-facebook-f me-2"></i>
                  </div>
                  <span v-if="currentPlan && currentPlan.is_influencer">🚫 </span>
                  Connect To Facebook
                </button>

                <a class="google-btn google-signed"
                   type="button"
                   v-if="settings && settings.google_user_id"
                   @click.prevent="socialLogout('google')">
                  <div class="google-icon-wrapper">
                    <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                  </div>
                  <p class="btn-text"><b>Logout {{ settings.google_name }}</b></p>
                </a>

                <a class="google-btn"
                   type="button"
                   v-if="googleAuthUrl && settings && !settings.google_user_id"
                   @click.prevent="googleAuth"
                   href="#">
                  <div class="google-icon-wrapper">
                    <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                  </div>
                  <p class="btn-text">
                    <b>
                      <span v-if="currentPlan && currentPlan.is_influencer">🚫 </span>
                      Sign in with Google
                    </b>
                  </p>
                </a>

                <div class="alert alert-danger mt-2"
                     v-if="settings && settings.google_user_id && !settings.google_ads_scope"
                     role="alert">
                  <strong><i class="fas fa-exclamation-triangle"></i> Please log out Google, log back in, and approve all permissions.</strong>
                </div>

                <div v-if="settings && settings.google_user_id && !settings.google_ads_scope">
                  <img style="background: white"
                       src="/images/instruction.gif"
                       class="w-100">
                </div>

                <template v-if="features && features.aweber">
                  <button class="social-btn amazon logout mt-3"
                          type="button"
                          v-if="settings && settings.aweber_owner_account_id"
                          @click.prevent="socialLogout('aweber')">
                    <div class="social-btn-icon">
                      <i class="fas fa-mail-bulk"></i>
                    </div>
                    Logout {{ settings.aweber_owner_name }}
                  </button>

                  <a :href="aweberAuthUrl"
                     v-else
                     class="social-btn amazon connect mt-3">
                    <div class="social-btn-icon">
                      <i class="fas fa-mail-bulk"></i>
                    </div>
                    Connect To Aweber
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-6" v-if="currentPlan && !currentPlan.lifetime_free">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-title">
              <div class="row">
                <div class="col-6">
                  <div class="p-caption">
                    <span class="caption-subject text-uppercase"> Plan</span>
                  </div>
                </div>
                <div class="col-6">
                  <button v-if="currentPlan"
                          @click="$refs.changePlanModal.show"
                          class="btn btn-primary btn-sm">
                    Change/Cancel Plan
                  </button>
                </div>
              </div>
            </div>
            <div class="portlet-body">
              <button v-if="loading"
                      class="btn btn-primary"
                      type="button" disabled="">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>

              <template v-else>
                <template v-if="currentPlan">
                  <div class="alert alert-danger mt-2 d-flex justify-content-between"
                       v-if="currentPlan.past_due"
                       role="alert">
                    <strong><i class="fas fa-exclamation-triangle"></i> Please Pay the due bills.</strong>

                    <a :href="currentPlan.past_due_invoice_url"
                       target="_blank"
                       class="btn btn-info">Pay Now</a>
                  </div>

                  <h4>{{ currentPlan.name }} (<span class="text-dark">${{ currentPlan.price }} /
                            <span v-if="currentPlan.recurring_interval_count > 1">{{ currentPlan.recurring_interval_count }}</span>
                            {{ currentPlan.recurring_interval }}</span>) <span class="text-success">- DEMO</span>
                  </h4>

                  <h5 class="text-danger" v-if="currentPlan.trial">Trial Expire at {{ currentPlan.trial_end }}</h5>
                  <p class="text-dark">{{ currentPlan.next_billing }}</p>

                  <div v-if="defaultPaymentMethod && defaultPaymentMethod.card">
                    <img style="height: 50px" :src="'/images/'+defaultPaymentMethod.card.brand+'.png'"> **** **** **** {{ defaultPaymentMethod.card.last4 }}
                  </div>
                </template>

                <a href="#"
                   @click.prevent="$refs.billingDetailsModal.show()"
                   class="text-info font-weight-bold"><i class="fas fa-money-bill"></i> Billing Details</a>

                <div class="d-flex mt-3">
                  <button v-if="currentPlan" @click="$refs.addCardModal.show()"
                          class="btn btn-primary w-50 mr-2">
                    <i class="fas fa-credit-card"></i> Change Card
                  </button>

                  <button v-if="currentPlan" @click="$refs.applyPromoModal.show()"
                          class="btn btn-success w-50">
                    <i class="fas fa-percentage"></i> Apply Promo Code
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <change-plan-modal v-if="currentPlan" ref="changePlanModal" />
  <add-card-modal ref="addCardModal" />
  <apply-promo-modal ref="applyPromoModal" />
  <billing-details-modal ref="billingDetailsModal" />
  <amazon-associate-modal ref="amazonAssociateModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import AddCardModal from "./modals/AddCardModal";
import BillingDetailsModal from "./modals/BillingDetailsModal.vue";
import ChangePlanModal from "./modals/ChangePlanModal.vue";
import ApplyPromoModal from "./modals/ApplyPromoModal";
import {mapGetters} from "vuex";
import settingsMixins from "@/mixins/settingsMixins";
import AmazonAssociateModal from "@/components/AmazonAssociateModal.vue";
export default {
  components: {PageTitleComponent, AddCardModal, BillingDetailsModal, ApplyPromoModal,
    ChangePlanModal, AmazonAssociateModal},
  mixins: [settingsMixins],
  data() {
    return {
      addPaymentView: null,
      googleAuthUrl: null,
      amazonAuthUrl: 'https://www.amazon.com/ap/oa?client_id='+process.env.VUE_APP_AMAZON_CLIENT_ID+'&scope=profile%20advertising::test:create_account%20advertising::campaign_management&response_type=code&redirect_uri=' + process.env.VUE_APP_AMAZON_REDIRECT_URI + '&state=amazon',
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      currentPlan: 'plan/getCurrentPlan',
      features: 'auth/getFeatures',
      defaultPaymentMethod: 'plan/getDefaultPaymentMethod',
      isCurrentPlanLoading: 'plan/getIsLoadingCurrentPlan'
    }),
    aweberAuthUrl() {
      const url = new URL("https://auth.aweber.com/oauth2/authorize");

      url.searchParams.append("response_type", "code");
      url.searchParams.append("client_id", process.env.VUE_APP_AWEBER_CLIENT_ID);
      url.searchParams.append("redirect_uri", process.env.VUE_APP_AWEBER_REDIRECT_URI);
      url.searchParams.append("scope", "account.read list.read email.write");
      url.searchParams.append("state", "aweber");

      return url;
    }
  },
  created() {
    this.axios.get('/settings/googleAuthUri')
      .then(response => {
        this.googleAuthUrl = response.data.data;
      })
      .catch(() => {});

    // Google Auth Check
    if ("state" in this.$route.query && this.$route.query.state === 'google') {
      this.axios.post('/setting/googleAccessToken', {
        code: this.$route.query.code
      }).then((response) => {
        this.$store.commit('settings/setSettings', response.data.data);

        this.checkStatus();
      });
    }

    // Aweber Auth Check
    if ("state" in this.$route.query && this.$route.query.state === 'aweber') {
      this.axios.post('/setting/aweberAccessToken', {
        code: this.$route.query.code
      }).then((response) => {
        this.$store.commit('settings/setSettings', response.data.data);
      });
    }

    // Amazon Auth Check
    if ("state" in this.$route.query && this.$route.query.state === 'amazon') {
      this.saveSettings({
        amazon_code: this.$route.query.code,
      })
    }
  },
  methods: {
    socialLogout(service) {
      this.axios.post('/settings/logout/'+service)
        .then(response => {
          this.$store.commit('settings/setSettings', response.data.data);
        });
    },
    checkStatus() {
      if (this.settings.amazon_user_id && (this.settings.fb_user_id || this.settings.google_user_id))
        this.$router.push({name: 'dashboard'});
    },
    googleAuth() {
      if (this.currentPlan && this.currentPlan.is_influencer) {
        this.showInfluencerLicenseMessage();
      } else {
        window.location.href = this.googleAuthUrl;
      }
    }
  }
}
</script>

<style scoped>
.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}
.row > [class*='col-'] {
    display: flex;
    flex-direction: column;
}
.social-btn.facebook.logout,
.social-btn.amazon.logout {
    background: #9ba7ca;
}

a.social-btn:hover{
    color: #fff;
}
</style>