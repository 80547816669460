<template>
  <div class="modal fade" id="change-plan-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-lg" role="document">
      <div class="modal-content popup-background border-0">
        <div class="modal-body">
          <section class="pricing-section bg-7">
            <div class="pricing pricing--norbu">
              <div class="pricing__item"
                   v-for="card in packages"
                   :key="card.id">
                <h3 class="pricing__title">{{ card.plan }}</h3>
                <div class="pricing__price"><span class="pricing__currency">$</span>{{ card.price }}<span class="pricing__period"> /&nbsp; {{ card.period }}</span></div>
                <h4 class="pricing__original_price mb-4" v-html="card.original_price"></h4>

                <div class="cp-spinner cp-round mb-4" v-if="modalLoading"></div>

                <template v-else>
                  <button class="btn btn-danger btn-rounded mb-4"
                          @click="cancelSubscription"
                          v-if="card.price_id === currentPlan.stripe_price">Cancel</button>

                  <button class="btn btn-info btn-rounded mb-4"
                          @click="changePlan(card.price_id)"
                          v-else>{{ currentPlanId && currentPlanId > card.id ? 'Downgrade' : 'Upgrade' }}</button>
                </template>

              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null,
      modalLoading: false,
      packages: [
        {
          id: 1,
          plan: "Monthly",
          price_id: process.env.VUE_APP_STRIPE_MONTHLY_PRICE_ID,
          product_id: process.env.VUE_APP_STRIPE_MONTHLY_PRODUCT_ID,
          price: 97,
          period: 'month',
          original_price: '&nbsp;',
        },
        {
          id: 2,
          plan: "Bi-annually",
          price_id: process.env.VUE_APP_STRIPE_SIX_MONTH_PRICE_ID,
          product_id: process.env.VUE_APP_STRIPE_SIX_MONTH_PRODUCT_ID,
          price: 497,
          period: '6 months',
          original_price: '(save of $85)',
        },
        {
          id: 3,
          plan: "Yearly",
          price_id: process.env.VUE_APP_STRIPE_YEARLY_PRICE_ID,
          product_id: process.env.VUE_APP_STRIPE_YEARLY_PRODUCT_ID,
          price: 897,
          period: 'year',
          original_price: '(save of $267)',
        }
      ],
    }
  },
  computed: {
    currentPlanId() {
      if (this.currentPlan)
        return this.packages.find(p => p.price_id === this.currentPlan.stripe_price) ? this.packages.find(p => p.price_id === this.currentPlan.stripe_price).id : null;

      return null;
    },
    ...mapGetters({
      settings: 'settings/getSettings',
      currentPlan: 'plan/getCurrentPlan',
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('change-plan-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    cancelSubscription() {
      this.$swal({
        title: 'Are you sure want to cancel subscription?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes, cancel it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLoading = true;

          this.axios.post('cancel-plan')
            .then(() => {
              location.reload()
            });
        }
      }).finally(() => this.modalLoading = false);
    },
    changePlan(priceId) {
      this.$swal({
        title: 'Are you sure want to change?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(7,118,124)',
        confirmButtonText: 'Yes, change it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLoading = true;
          this.axios.post('change-plan', {
            price_id: priceId
          }).then(response => {
            this.hide();
            this.$store.commit('plan/setCurrentPlan', response.data.data);
          }).finally(() => {
            this.modalLoading = false;
          })
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../components/subs-popup/style/style";
@import "@/assets/scss/pricing.scss";
</style>