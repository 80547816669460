<template>
  <h1>Please wait...</h1>
</template>

<script>
export default {
  beforeCreate() {
    this.axios.post('/check-invitation', {
        code: this.$route.query.invitation
      })
      .catch(() => {})
      .finally(() => {
        window.location.href = process.env.VUE_APP_BASE
      });
  }
}
</script>